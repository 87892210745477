import styled from 'styled-components';
import { MaxContainer, Title, Paragraph, TitleUnderline } from '../styles';
import RequestACallbackForm from 'components/RequestACallback/RequestACallbackForm';

const LearningAndEarnings = () => (
  <Background>
    <Container className="container">
      <div>
        <Title className="desktop">
          StockEdge <mark>Club</mark> {'\n'}
        </Title>
        <mark>
          <TitleUnderline />
        </mark>{' '}
        {'\n'}
        <Paragraph>
          India’s fastest-growing Stock Market Investing and <br />
          Trading Community. Join over 25,000+ retail investors
          <br /> and more than 50 experts to make the right move <br />
          and beat the market.
        </Paragraph>
      </div>
      <RequestCallContainer>
        <RequestACallbackForm setModal={''} />
      </RequestCallContainer>
    </Container>
  </Background>
);

export default LearningAndEarnings;

const RequestCallContainer = styled.div`
  width: min(100%, 600px);
  padding-bottom: 20px;
`;

const Background = styled.div`
background:url(${require('../assets/bg/video.mp4')})
width: 100%;
min-height: min(480px, 100vh);
border-bottom: 1px solid #363636;
overflow:hidden;
@media screen and (max-width: 768px) {
  .container {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 520px) {
  min-height: min(450px, 100vh);
}





`;

const Container = styled(MaxContainer)`
  max-width: 1400px !important;

  @media screen and (max-width: 1550px) {
    max-width: 1200px !important;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 780px) {
    flex-direction: row;
    justify-content: space-between;
  }

  ${Title} {
    text-align: center;

    &.desktop {
      // display: none;
    }
    &.mobile {
      display: block;
    }
  }

  ${Paragraph} {
    color: white;
    line-height: 36px;
    font-weight: 400px;
    font-size: 0.9rem;
    line-height: 1.5rem;
    text-align: center;
    @media screen and (max-width: 520px) {
      padding: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    ${TitleUnderline} {
      margin: 1rem auto;
    }
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    padding: 0 15px;
    width: calc(100% - 30px);
    margin-top: 3rem;
    // margin-left: 15rem;
    ${Title} {
      text-align: left;
      font-size: 2rem;
      line-height: 2.5rem;
      &.desktop {
        display: block;
      }
      &.mobile {
        display: none;
      }
    }

    ${Paragraph} {
      color: white;
      font-size: 1.2rem;
      line-height: 1.9rem;
      text-align: left;
    }
  }

  @media screen and (min-width: 1300px) {
    ${Title} {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }
  }
`;
