import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { GlobalStyle } from './styles';
import HashLinkObserver from 'components/HashLinkObserver';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <HashLinkObserver />
        <GlobalStyle />
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>
);
