import { Fragment, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import BudgetDecoded from './BudgetDecoded';

const WhyYouAttened = lazy(() => import('./WhyYouAttened'));
const MeetYourSpeakers = lazy(() => import('./MeetYourSpeakers'));
const EventItinerary = lazy(() => import('./EventItinerary'));
const FeedbackPage = lazy(() => import('./FeedbackPage'));
const Faq = lazy(() => import('./Faq'));
const Footer = lazy(() => import('../../components/Footer'));

const TradingMarathon = () => {
  return (
    <Fragment>
      <Helmet>
        ‍<title>Budget Decoded | StockEdge Club</title>‍
        <meta
          name="description"
          content="An exclusive 2-day power packed event with 15 market experts and infinite learning on 16th and 17th October for StockEdge Club members only. Reserve Spot now."
        />
      </Helmet>
      ‍
      <BudgetDecoded />
      <Suspense fallback={<Fragment />}>
        <WhyYouAttened />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <MeetYourSpeakers />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <EventItinerary />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <FeedbackPage />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <Faq />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <Footer />
      </Suspense>
    </Fragment>
  );
};

export default TradingMarathon;
